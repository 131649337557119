@import "../../styles/variables";
@import "../../styles/mixins";

.image-uploader {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__file-input {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 10px 0;

    input[type="file"] {
      display: none;
    }

    label {
      padding: 4px 10px;
      margin: 10px;
      //border-radius: $button-border-radius;
      border: 1px solid grey;
      cursor: pointer;
      font-size: 10px;
    }
  }

  &__image-preview-container {
    @include for-phone-only {
      max-height: 100%;
      max-width: 100%;
    }
    max-height: 500px;
    max-width: 500px;
  }

  &__image-preview {
    height: 100%;
    width: 100%;
  }

}